<!-- by LinHuiMing -->

<template>
  <div class="operator-container">
    <header v-if="$slots.header" id="header" class="header">
      <slot name="header"></slot>
    </header>
    <main v-if="$slots.content" id="main" class="content">
      <slot name="content"></slot>
    </main>
    <footer v-if="$slots.footer" id="footer" class="footer">
      <slot name="footer"></slot>
    </footer>

    <div v-if="$slots.dialog" id="dialog" class="dialog">
      <slot name="dialog"></slot>
    </div>

    <div v-if="$slots.default" class="default">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  props: {},
  data() {
    return {};
  },

  computed: {},

  created() {},

  mounted() {},

  methods: {},
};
</script>
<style lang="stylus">
.operator-container {
  width 100%
  .header {

    .el-form-item {
      margin-bottom: 0.25rem !important;
    }
  }
}
</style>

<style lang="stylus" scoped>
.operator-container
  width 100%
  .header
    padding .5rem .5rem
    border 1px solid $border-color
    border-radius 0.25rem
  .content
    margin .5rem 0
    // border 1px solid $border-color
    // padding .5rem 1rem
    border-radius 0.25rem

  .footer
    // border 1px solid $border-color
    // padding .5rem 1rem
    text-align center
    // border-radius 0.25rem
</style>
