<template>
  <operator-container>
    <template #content>
      <div class="container">
        <div class="header-content">
          <p class="subject">核心功能</p>
          <p class="sub-description">帮助卖家精细化运营，助力业务增长</p>
        </div>

        <ul class="module-group">
          <li
            v-for="(item, index) in options.functionModuleList"
            :key="index"
            class="module-item"
          >
            <a :href="item.link" target="_blank">
              <div class="module-content">
                <img :src="item.imageUrl" alt="暂无图片" />
                <p class="title">{{ item.title }}</p>
                <p class="sub-title">{{ item.description }}</p>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </template>
  </operator-container>
</template>

<script>
import OperatorContainer from "components/container/operator-container";
import { YU_QUE_URL_PREFIX } from "@/common/js/config";

export default {
  name: "AiLandingPage",
  components: {
    OperatorContainer,
  },
  mixins: [],
  props: {},
  data() {
    return {
      options: {
        functionModuleList: [
          {
            imageUrl: require("./images/collectProduct.png"),
            title: "采集产品",
            description: "一键采集1688产品和阿里巴巴产品",
            link: `${YU_QUE_URL_PREFIX}pKrPk`,
          },
          {
            imageUrl: require("./images/imageTranslation.png"),
            title: "图片翻译",
            description: "图片翻译(中翻英)",
            link: `${YU_QUE_URL_PREFIX}RJaaN`,
          },
          {
            imageUrl: require("./images/smartCutout.png"),
            title: "智能抠图",
            description: "图片抠图(白底图)",
            link: `${YU_QUE_URL_PREFIX}OldZW`,
          },
          {
            imageUrl: require("./images/photoPiracy.png"),
            title: "处理盗图",
            description: "一键处理疑似盗图",
            link: `${YU_QUE_URL_PREFIX}LQu5L`,
          },
          {
            imageUrl: require("./images/searchProduct.png"),
            title: "以图搜品",
            description: "用于快速填写1688产品参数",
            link: `${YU_QUE_URL_PREFIX}EEUje`,
          },
          {
            imageUrl: require("./images/productQuality.png"),
            title: "产品质量分",
            description: "检测产品信息质量分",
            link: `${YU_QUE_URL_PREFIX}ievnl`,
          },
          {
            imageUrl: require("./images/keywordCollect.png"),
            title: "收集关键词",
            description: "一键采集阿里热搜词",
            link: `${YU_QUE_URL_PREFIX}BUgXg`,
          },
          {
            imageUrl: require("./images/keywordTranslation.png"),
            title: "关键词翻译",
            description: "关键词翻译(英翻中)",
            link: `${YU_QUE_URL_PREFIX}vPbhS`,
          },
          {
            imageUrl: require("./images/searchRank.png"),
            title: "查询排名",
            description: "一键查询关键词排名",
            link: `${YU_QUE_URL_PREFIX}lUIf5`,
          },
          {
            imageUrl: require("./images/keywordUseCount.png"),
            title: "关键词次数查询",
            description: "关键词使用次数查询",
            link: `${YU_QUE_URL_PREFIX}h9xQm`,
          },
          {
            imageUrl: require("./images/report.png"),
            title: "店铺诊断",
            description: "一键诊断店铺数据报告",
            link: `${YU_QUE_URL_PREFIX}zjL6y`,
          },
          {
            imageUrl: require("./images/productQuotation.png"),
            title: "产品报价单",
            description: "1688以图搜品导出产品报价单",
            link: `${YU_QUE_URL_PREFIX}nB0jN`,
          },
        ],
      },
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="stylus" scoped>

.container {
  display flex
  flex-direction column

  .header-content {
    display flex
    flex-direction column
    justify-content center
    align-items center

    .subject {
      margin-top 1rem
      font-size 2rem
      font-weight 400
      color #17233d
      letter-spacing 6px
    }

    .sub-description {
      margin 2rem 0
      font-size 1.5rem
      font-weight 300
      color #515a6e
      letter-spacing 2px
    }
  }

  .module-group {
    display flex
    justify-content flex-start
    align-items center
    flex-wrap wrap

    .module-item {
      width 16.6%
      margin .5rem 0
      cursor pointer

      .module-content {
        display flex
        flex-direction column
        justify-content center
        align-items center

        img {
          width 7rem
          height 7rem
          transition: all .5s;
        }

        img:hover {
          transform scale(1.2)
          transition all .2
        }

        .title {
          margin 1.5rem
          font-size 1.5rem
          color #17233d
        }

        .sub-title {
          color #515a6e
        }
      }
    }
  }
}
</style>
